import moment from 'moment-timezone';

export const chartChangeRange = (from, to, replaceFromProps, locationPathname) => {
  const min = moment(from).toISOString();
  const max = moment(to).toISOString();

  const newSearch = { from: min, to: max, subType: 'range' };
  replaceFromProps(`${locationPathname}?${new URLSearchParams(newSearch).toString()}`);
};

export const generateRequestQuery = (from, to, type) => {
  const objMap = {
    t: () => ({ from: moment().startOf('day').toISOString(), to: moment().toISOString() }),
    h: () => ({ from: moment().subtract(1, 'hour').toISOString(), to: moment().toISOString() }),
    d: () => ({ from: moment().subtract(1, 'day').toISOString(), to: moment().toISOString() }),
    w: () => ({ from: moment().subtract(1, 'week').toISOString(), to: moment().toISOString() })
  };

  const range = { from, to, type };

  if (!range.from || !range.to) {
    range.from = objMap[type || 't']?.().from;
    range.to = objMap[type || 't']?.().to;
  }

  if (!range.type) {
    if (!from || !to) {
      range.type = 't';
    } else {
      const hoursDiff = moment(range.to).diff(moment(range.from), 'hours');

      if (hoursDiff > 24) {
        range.type = 'w';
      }

      if (hoursDiff <= 24) {
        range.type = 'd';
      }

      if (hoursDiff <= 1) {
        range.type = 'h';
      }
    }
  }

  range.scaleMs = moment(range.to).diff(moment(range.from), 'ms');

  return range;
};

export const getPlotLine = (houseFuse, houseFuseLineColor, houseFuseLabelColor) => ({
  id: 'plotLine',
  afterDraw(chart, _args, options) {
    if (houseFuse) {
      const {
        ctx,
        chartArea: { right, left, width },
        scales: { y }
      } = chart;
      ctx.save();

      ctx.strokeStyle = houseFuseLineColor;
      ctx.lineWidth = 3;
      ctx.setLineDash([10, 10]);
      ctx.strokeRect(left, y.getPixelForValue(houseFuse), width, 0);
      ctx.strokeRect(left, y.getPixelForValue(houseFuse * -1), width, 0);
      ctx.restore();

      if (options.isShowLabel) {
        ctx.font = '500 16px Poppins';
        ctx.fillStyle = houseFuseLabelColor;
        ctx.strokeStyle = 'transparent';
        ctx.lineWidth = 20;
        ctx.strokeRect(right - 120, y.getPixelForValue(houseFuse) - 14, width, 0);
        ctx.fillText(i18n.t('houseFuseLabel'), right - 10, y.getPixelForValue(houseFuse) - 7);
        ctx.strokeStyle = 'transparent';
        ctx.lineWidth = 20;
        ctx.strokeRect(right - 120, y.getPixelForValue(houseFuse * -1) - 14, width, 0);
        ctx.fillText(i18n.t('houseFuseLabel'), right - 10, y.getPixelForValue(houseFuse * -1) - 7);
        ctx.textAlign = 'right';
        ctx.restore();
      }
    }
  }
});

export const getNoData = (noDataLabelColor) => ({
  beforeDraw(chart) {
    if (chart.data.datasets[0].data.every((item) => item === 0)) {
      const {
        ctx,
        chartArea: { width, height }
      } = chart;
      ctx.save();

      ctx.font = '20px Poppins';
      ctx.fillStyle = noDataLabelColor;
      ctx.fillText(i18n.t('chartsNoData'), width / 1.5, height / 2);
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.restore();
    }
  }
});
